<template>
    <el-dialog width="60%" :title="$t('AI评分')" :visible.sync="loadingDialogVisible">
        <div class="flex flex-column flex-h-center padding">
            <div class="flex flex-h-center flex-v-center">
                <el-progress color="#e6a13c" :format="format" type="circle" :percentage="percentage"></el-progress>
                <div class="margin-l">
                    <div class="margin-x bold" style="color:#000;font-size: 34px;">AI拼命评分中...</div>
                    <div class="flex margin-lt">
                        <div>
                            <div class="margin mt color-primary">{{$t('极速评分')}}</div>
                            <div class="margin mt color-primary">{{$t('可关闭页面稍候查看')}}</div>
                        </div>

                        <el-image style="height:100px;width: auto;margin-left: 10px;" src="/web/image/score.png"></el-image>
                    </div>
                </div>
            </div>

            <div style="margin-top: 200px;">
                <div class="center mt bold">
                    {{ content.endsWith(".wav") ? $t('我的录音') : $t('我的答案') }}
                </div>
                <div v-if="content.endsWith('.wav')" class="flex flex-v-center flex-h-center margin-t">
                    <div> <i @click="play()" :class="playing ? 'el-icon-video-pause' : 'el-icon-video-play'"
                            class="color-primary" style="font-size: 40px;"></i>
                    </div>
                    <div style="width:240px;" class="margin-x"><el-slider v-model="value1" :max="duration"></el-slider>
                    </div>
                    <div>{{ format_time1(value1) }}/{{ format_time1(duration) }}</div>
                </div>

                <div v-else class="flex flex-v-center flex-h-center margin-t word">
                    {{ content }}
                </div>
            </div>
        </div>
        <div slot="footer" class="center">
            <el-button @click="loadingDialogVisible = false">关 闭</el-button>
        </div>
        <audio ref="audio" @loadeddata="onLoad" src="" @play="onPlay" @pause="onPause" @timeupdate="timeUpdate"></audio>
    </el-dialog>
</template>

<script>
export default {
    name: "evaluateDialog",
    data() {
        return {
            loadingDialogVisible: false,
            value1: 50,
            playing: false,
            duration: 0,
            percentage: 0,
            content: "",
        };
    },
    props: {
    },

    created() {
        this.timer();
    },

    methods: {
        hide(val) {
            if (val) {
                this.percentage = 100;
            } else {
                this.loadingDialogVisible = false;
            }
        },

        show(content) {
            this.content = content.toString();
            this.duration = 0;
            this.percentage = 0;
            this.loadingDialogVisible = true;

            if (content.endsWith(".wav")) {
                this.$nextTick(() => {
                    this.$refs.audio.src = this.$host + content;
                    this.$refs.audio.load();
                })
            }
        },
        format() {
            return this.percentage == 100 ? '评分完成' : '评分中'
        },
        timer() {
            setTimeout(() => {
                if (this.loadingDialogVisible) {
                    if (this.percentage >= 100) {
                        this.percentage = 100;
                        this.loadingDialogVisible = false;
                        this.$nextTick(() => {
                            this.$emit("ended");
                        })
                    }

                    if (this.percentage < 100) {
                        this.percentage += 10;
                    }

                    if (this.duration == 0) {
                        this.duration = this.$refs.audio.duration > 9999 ? 0 : this.$refs.audio.duration;
                    }
                }
                this.timer();
            }, 500);
        },
        onLoad() {
            this.$nextTick(() => {
                this.duration = this.$refs.audio.duration > 9999 ? 0 : this.$refs.audio.duration;
            })
        },

        format_time1(time) {
            time = Math.ceil(time);
            let minute = parseInt(time / 60);
            let second = time - minute * 60;
            return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
        },


        play() {
            if (this.playing) {
                this.$refs.audio.pause();
            } else {
                this.$refs.audio.play();
            }
        },

        onPlay() {
            this.playing = true;
        },
        onPause() {
            this.playing = false;
        },
        timeUpdate() {
            this.value1 = this.$refs.audio.currentTime;
        },
    },
};
</script>
<style scoped></style>
