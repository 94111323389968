<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" @close="close()" width="600px">


            <el-image src="/web/image/logo0.png" style="width:100px;position: absolute; top:-40px;left:-20px"></el-image>
            <div class="padding" style="background: #fff;border-radius: 20px;">
                <div class="center" style="position: relative;bottom: 40px;">
                    <h2 class="lt">{{$t('AI评测')}}</h2>

                </div>


                <div>

                    <div class="flex flex-h-center flex-v-center">
                        <div>{{$t('今日剩余')}}</div>
                        <h1 style="font-size: 36px; color: #e6a13c;position: relative;bottom: 6px;" class="margin-x">{{
                            times }}/5</h1>
                        <div>次</div>
                    </div>

                    <div class="margin-t center">{{$t('非VIP用户每天仅有5次免费AI评测机会')}}!</div>

                    <div class="center margin-lt">
                        <el-button @click="vip()" type="primary" round>{{$t('无限AI评分')}}</el-button>

                        <el-button :disabled="times <= 0" @click="submit()" plain style="margin-left: 100px;" type="primary"
                            round>{{$t('立即评分')}}</el-button>
                    </div>

                    <div class="st margin-t center">{{$t('提醒：加海澳微信即可有机会领取7天VIP，每个微信号可领取一次')}}</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "vipcodeDialog",
    data() {
        return {
            callback: null,
            dialogVisible: false,
            times: 0
        };
    },
    props: {
    },
    methods: {
        show(callback) {
            this.callback = callback;
            this.$http.post("/api/source/evaluate/times").then((times) => {
                this.times = times;
                this.dialogVisible = true;
            })
        },

        vip() {
            this.$router.push("/main/vip")
        },

        submit() {
            this.dialogVisible = false;
            if (this.times > 0) {
                this.callback(1);
            } else {
                this.callback(0);
            }
        },

        close() {
            this.callback(0);
        }
    },
};
</script>
<style scoped>
.code-input>>>.el-input__inner::placeholder {
    color: #999;
}

.code-input {
    border-radius: 20px;
    border: 1px solid #e6a13c;
}

.code-input>>>.el-input__inner {
    background-color: #ccc;
    border-radius: 20px;
    color: #333;
}

.code-input>>>.el-input__suffix:hover {
    border-color: #333 !important;
}

.code-input>>>.el-dialog {
    background: transparent;
}

.code-input>>>.el-dialog__headerbtn .el-dialog__close {
    font-size: 30px;
}
</style>
