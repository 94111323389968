<template>
    <div class="record-list">
        <el-empty v-if="list.length == 0" :description="`${$t('您还没有学习记录')}！`"></el-empty>
        <div v-for="(item, index) in list" :key="item.id">
            <div class="flex">
                <el-image v-if="!mock" class="avatar margin-x"
                    :src="item.user.avatar.indexOf('http') == 0 ? item.user.avatar : $host + item.user.avatar">
                </el-image>

                <div class="flex1">
                    <div v-if="!mock" class="flex flex-v-center flex-between">
                        <div class="flex flex-v-center" style="margin-bottom:5px;">

                            <el-tag v-if="item.user.role == 4" size="mini" style="margin-right:10px;" effect="dark">{{
                                $t('课程VIP') }} (PTE+CCL)</el-tag>
                            <el-tag v-else-if="item.user.role == 3" size="mini" style="margin-right:10px;"
                                effect="dark">{{
                                    $t('课程VIP') }} (CCL)</el-tag>
                            <el-tag v-else-if="item.user.role == 2" size="mini" style="margin-right:10px;"
                                effect="dark">{{
                                    $t('课程VIP') }} (PTE)</el-tag>
                            <el-tag v-if="item.user.role == 1" size="mini" style="margin-right:10px;"
                                effect="dark">VIP</el-tag>
                            <h4 class="st1">{{ item.user.nickName }}</h4>
                            <div class="st2 margin-l"
                                :class="{ 'margin-x': (item.content.indexOf('.wav') == -1 && item.content.indexOf('.mp3') == -1) }">
                                {{
                                    item.record_time
                                }}</div>
                        </div>
                    </div>


                    <div v-if="type == 'ccl'">
                        <div v-for="(row, index) in item.content" :key="index" style="margin:5px 0 20px">
                            <div class="flex flex-v-center">
                                <div class="st1" style="width:16px;font-weight: 700;">{{ parseInt(row.index) + 1 }}.
                                </div>
                                <wave-player class="margin-x" borderRadius="10px"
                                    :src="row.url.indexOf('http') == 0 ? row.url : $host + row.url"></wave-player>
                            </div>

                            <div class="margin-ts" v-if="item.audit_content[row.index]">

                                <div class="st1 flex ">
                                    <el-tag size="small">{{ $t('老师点评') }}</el-tag>
                                    <div class="margin-l" style="color: #ccc;">{{ item.audit_time }}</div>

                                </div>
                                <div class="st" style="margin-left: 30px;">
                                    <div>{{
                                        item.audit_content[row.index].text }}</div>
                                    <wave-player v-if="item.audit_content[row.index].url" borderRadius="10px"
                                        :src="item.audit_content[row.index].url.indexOf('http') == 0 ? item.audit_content[row.index].url : $host + item.audit_content[row.index].url"></wave-player>
                                </div>
                                <!-- <el-divider></el-divider> -->
                            </div>
                        </div>

                        <div class="flex flex-v-center margin-t">
                            <el-tooltip class="st pointer icon-l" :content="$t('分享答案')" placement="top">
                                <i @click="share(item)" class="el-icon-share"></i>
                            </el-tooltip>

                            <div class="flex flex-v-center">
                                <el-image @click="vote(item)"
                                    :src="'/web/image/zan' + (item.vote_id ? '_checked' : '') + '.png'"
                                    style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                <span class="st">{{ item.votes_count }}</span>
                            </div>

                            <!-- <el-button class="margin-l" size="mini" v-if="item.user.id == $store.state.user.id"
                                                type="primary" @click="removeRecord(item.id, index)" icon="el-icon-delete" circle>
                                            </el-button> -->
                            <el-tooltip v-if="item.user.id == $store.state.user.id" class="st pointer icon-l margin-l"
                                :content="$t('删除')" placement="top">
                                <i @click="removeRecord(item.id, index)" class="el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </div>

                    <!--one-->
                    <div v-if="type == 'pte'">

                        <div class="flex flex-v-center"
                            v-if="(item.content.endsWith('.wav') || item.content.endsWith('.mp3'))">
                            <wave-player borderRadius="10px"
                                :src="item.content.indexOf('http') == 0 ? item.content : $host + item.content"></wave-player>
                            <el-tag v-if="item.ra_flag" class="margin-l" size="small">一句话读法</el-tag>
                            <el-button :disabled="evaluate_item.id == item.id" @click="evaluate(item, -1, index)"
                                icon="el-icon-files"
                                v-if="!mock && item.user_id == $store.state.user.id && $store.getters.getRole() == 0 && item.result_time == null"
                                class="margin-x" size="small" round type="primary">{{ evaluate_item.id ==
                                    item.id ? `${$t('AI测评中')}...` : $t('AI测评') }}</el-button>

                            <el-button :disabled="evaluate_item.id == item.id" @click="evaluate(item, -1, index)"
                                icon="el-icon-files"
                                v-if="!mock && item.user_id == $store.state.user.id && $store.getters.getRole() > 0 && item.result_time == null"
                                class="margin-l" size="small" round type="primary">{{ evaluate_item.id ==
                                    item.id ? `${$t('AI测评中')}...` : $t('AI测评') }}</el-button>

                            <el-button @click="view(item)" v-if="item.result_time" class="margin-l" size="small" round
                                type="primary">{{ tag == 'ASQ' ? item.score : format_number(item.score * 0.9) }}/{{ tag
                                    ==
                                    'ASQ' ? 1 : 90 }}
                                {{ $t('查看评分') }}</el-button>

                            <el-button icon="el-icon-coordinate"
                                v-if="!mock && item.user_id == $store.state.user.id && $store.state.user.certificate && (item.status == 0 || item.status == 1)"
                                class="margin-x" size="small" :disabled="item.status == 1" @click="applyCheck(item)"
                                round type="primary">{{
                                    item.status == 1 ? `${$t('批改中')}...` : $t('申请批改')
                                }}</el-button>

                            <el-tooltip v-if="!mock" class="st pointer icon-l margin-l" :content="$t('分享答案')"
                                placement="top">
                                <i @click="share(item)" class="el-icon-share"></i>
                            </el-tooltip>

                            <div v-if="!mock" class="flex flex-v-center">
                                <el-image @click="vote(item)"
                                    :src="'/web/image/zan' + (item.vote_id ? '_checked' : '') + '.png'"
                                    style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                <span class="st">{{ item.votes_count }}</span>
                            </div>



                            <el-tooltip v-if="!mock && item.user.id == $store.state.user.id"
                                class="st pointer icon-l margin-l" :content="$t('删除')" placement="top">
                                <i @click="removeRecord(item.id, index)" class="el-icon-delete"></i>
                            </el-tooltip>
                        </div>

                        <div :class="{ 'flex': tag != 'SST' }" class="mt " v-else>
                            <div v-if="mock && ['FIB-RW', 'MCM-R', 'RO', 'FIB-R', 'MCS-R', 'MCM-L', 'FIB-L', 'HCS', 'MCS-L', 'SMW', 'HIW', 'WFD', 'SST'].indexOf(tag) != -1"
                                class="mt bold" style="margin-right: 8px;flex-shrink: 0;">{{ $t("我的答案") }}:</div>
                            <div class="flex flex-v-center flex-wrap"
                                v-if="tag == 'MCM-L' || tag == 'MCM-R' || tag == 'HIW'">
                                <div class="flex flex-v-center" v-for="(word, index) in item.content.split(',')"
                                    :key="index">
                                    <span
                                        :class="{ 'wrong0': answers.length > 0 && answers.indexOf(word) == -1, 'right': answers.length > 0 && answers.indexOf(word) != -1 }">{{
                                            word
                                        }}</span>

                                    <span v-if="index < item.content.split(',').length - 1">,</span>
                                </div>

                                <div>,{{ (answers.filter((element) =>
                                    !item.content.split(',').includes(element))).join(',') }}</div>
                            </div>

                            <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'WFD'">
                                <span class="word" :class="item.class" v-for="(item, index) in item.contents"
                                    :key="index">
                                    {{ item.word }}
                                </span>
                            </div>

                            <!-- <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'WFD'">
                                            <div class="flex flex-v-center" v-for="(word, index) in item.content.split(' ')"
                                                :key="index">
                                                <span
                                                    :class="{ 'wrong1': answers.length > 0 && answers.indexOf(word.replace(/\W/g, '')) == -1 && answers.indexOf(word.replace(/\W/g, '').toLowerCase()) == -1, 'right': answers.length > 0 && (answers.indexOf(word.replace(/\W/g, '')) != -1 || answers.indexOf(word.replace(/\W/g, '').toLowerCase()) != -1) }">{{
                                                        word
                                                    }}</span>

                                                <span v-if="index < item.content.split(' ').length - 1">&nbsp;</span>
                                            </div>
                                        </div> -->

                            <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'RO'">
                                <div class="flex flex-v-center" v-for="(word, index) in item.content.split(',')"
                                    :key="index">
                                    <span>{{ word.split('/')[0] }}</span>

                                    <span v-if="index < item.content.split(',').length - 1">,</span>
                                </div>
                            </div>


                            <row-span :id="item.id" v-else-if="tag == 'SST' || tag == 'SWT'">
                                <div :class="{ 'word-mock': mock }" class="flex flex-v-center ls1"
                                    v-for="(word, index) in item.content.split('\n')" :key="index">
                                    <span v-html="word ? word : '&nbsp;'"></span>
                                </div>
                            </row-span>

                            <row-span :id="item.id" v-else-if="tag == 'WE'">
                                <div :class="{ 'word-mock': mock }" class="flex flex-v-center ls1"
                                    v-for="(word, index) in item.content.split('\n')" :key="index">
                                    <div v-if="word"> {{ word }}</div>
                                    <div v-else style="height: 10px;"></div>
                                </div>
                            </row-span>

                            <!-- <div style="position: relative;" :ref="'row' + item.id" class="flex flex-v-center flex-wrap"
                                            >
                                            <div class="flex flex-v-center ls1" v-for="(word, index) in item.content.split(',')"
                                                :key="index">
                                                <span>{{ word }}</span>
                                            </div>

                                            <div v-if="$refs['row'+item.id] && $refs['row'+item.id][0] && $refs['row'+item.id][0].offsetHeight>84" style="position:absolute;right:0;bottom:-30px" class="st">
                                                查看更多</div>
                                        </div> -->



                            <div class="flex flex-v-center flex-wrap" v-else>
                                <div class="flex flex-v-center" v-for="(word, index) in item.content.split(',')"
                                    :key="index">
                                    <span
                                        :class="{ 'wrong0': answers.length > 0 && answers[index].split('/').indexOf(word) == -1, 'right': answers.length > 0 && answers[index].split('/').indexOf(word) != -1 }">{{
                                            word
                                        }}</span>
                                    <span v-if="answers.length > 0"
                                        :class="{ 'wrong': answers.length > 0 && answers[index].split('/').indexOf(word) == -1 }">
                                        {{ answers[index].split('/').indexOf(word) == -1 ? '(' + answers[index] + ')' :
                                            ''
                                        }}
                                    </span>
                                    <span v-if="index < item.content.split(',').length - 1">,</span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-v-center margin-ts">

                            <div v-if="mock && ['FIB-RW', 'MCM-R', 'RO', 'FIB-R', 'MCS-R', 'MCM-L', 'FIB-L', 'HCS', 'MCS-L', 'SMW', 'HIW', 'WFD'].indexOf(tag) != -1"
                                class="mt bold" style="margin-right: 8px;">{{ $t("我的得分") }}:</div>

                            <template v-if="['SWT', 'WE', 'SST'].indexOf(tag) != -1">
                                <el-button size="mini" round type="primary" @click="showScore(item)"
                                    v-if="((mock && item.status == 1) || item.result_time)">{{
                                        item.score
                                    }}/{{ total }} {{ $t('查看评分') }}
                                </el-button>

                                <el-button :disabled="evaluate_item.id == item.id" @click="evaluate(item, -1, index)"
                                    icon="el-icon-files"
                                    v-if="item.user_id == $store.state.user.id && item.result_time == null && !mock"
                                    size="small" round type="primary">{{ evaluate_item.id ==
                                        item.id ? `${$t('AI测评中')}...` : $t('AI测评') }}</el-button>
                            </template>

                            <div @click="showScore(item)"
                                v-if="(!mock || mock && item.status == 1) && item.content.indexOf('.wav') == -1 && item.content.indexOf('.mp3') == -1 && ['SWT', 'WE', 'SST'].indexOf(tag) == -1"
                                class="st flex flex-v-center">
                                {{ item.score }}/{{ total
                                }}分

                                <el-button
                                    v-if="mock && (['FIB-R', 'FIB-RW', 'MCM-R', 'RO', 'MCS-R'].indexOf(tag) != -1) && source.analysis"
                                    @click="$bus.$emit('show_analysis', source)" class="margin-l" size="mini" round
                                    type="primary">{{ tag == "RO" ?
                                        $t("顺口溜") : $t("答案解析") }}</el-button>

                            </div>


                            <div v-if="!mock && (item.content.indexOf('.wav') == -1 && item.content.indexOf('.mp3') == -1)"
                                class="flex flex-v-center">
                                <el-tooltip class="st pointer icon-l margin-l" :content="$t('分享答案')" placement="top">
                                    <i @click="share(item)" class="el-icon-share"></i>
                                </el-tooltip>

                                <div class="flex flex-v-center">
                                    <el-image @click="vote(item)"
                                        :src="'/web/image/zan' + (item.vote_id ? '_checked' : '') + '.png'"
                                        style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                    <span class="st">{{ item.votes_count }}</span>
                                </div>

                                <!-- <el-button class="margin-l" size="mini" v-if="item.user.id == $store.state.user.id"
                                                type="primary" @click="removeRecord(item.id, index)" icon="el-icon-delete" circle>
                                            </el-button> -->
                                <el-tooltip v-if="item.user.id == $store.state.user.id"
                                    class="st pointer icon-l margin-l" :content="$t('删除')" placement="top">
                                    <i @click="removeRecord(item.id, index)" class="el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <!-- one end-->
                    <div v-if="type == 'pte'">
                        <div v-if="item.items && item.collapse">
                            <el-divider></el-divider>
                            <div v-for="(item1, index1) in item.items" :key="item1.id">

                                <div class="flex flex-v-center"
                                    v-if="(item1.content.endsWith('.wav') || item1.content.endsWith('.mp3'))">
                                    <wave-player borderRadius="10px"
                                        :src="item1.content.indexOf('http') == 0 ? item1.content : $host + item1.content"></wave-player>
                                    <el-tag v-if="item.ra_flag" class="margin-l" size="small">{{ $t('一句话读法') }}</el-tag>
                                    <el-button :disabled="evaluate_item.id == item1.id"
                                        @click="evaluate(item1, index, index1)" icon="el-icon-files"
                                        v-if="item.user_id == $store.state.user.id && $store.getters.getRole() == 0 && item.result_time == null"
                                        class="margin-l" size="small" round type="primary">{{ evaluate_item.id ==
                                            item1.id ? `${$t('AI测评中')}...` : $t('AI测评') }}</el-button>

                                    <el-button :disabled="evaluate_item.id != item1.id"
                                        @click="evaluate(item1, index, index1)" icon="el-icon-files"
                                        v-if="item.user_id == $store.state.user.id && $store.getters.getRole > 0 && item.result_time == null"
                                        class="margin-l" size="small" round type="primary">{{ evaluate_item.id ==
                                            item1.id ? `${$t('AI测评中')}...` : $t('AI测评') }}</el-button>

                                    <el-button @click="view(item1)" v-if="item1.result_time" class="margin-l"
                                        size="small" round type="primary">{{ tag == 'ASQ' ? item1.score :
                                            format_number(item1.score * 0.9) }}/{{ tag ==
                                            'ASQ' ? 1 : 90 }}
                                        {{ $t('查看评分') }}</el-button>

                                    <el-button icon="el-icon-coordinate"
                                        v-if="item.user_id == $store.state.user.id && $store.state.user.certificate && (item.status == 0 || item.status == 1)"
                                        class="margin-l" size="small" :disabled="item1.status == 1"
                                        @click="applyCheck(item1)" round type="primary">{{
                                            item.status == 1 ? `${$t('批改中')}...` : $t('申请批改')
                                        }}</el-button>


                                    <el-tooltip class="st pointer icon-l margin-l" :content="$t('分享答案')"
                                        placement="top">
                                        <i @click="share(item1)" class="el-icon-share"></i>
                                    </el-tooltip>


                                    <div class="flex flex-v-center">
                                        <el-image @click="vote(item1)"
                                            :src="'/web/image/zan' + (item1.vote_id ? '_checked' : '') + '.png'"
                                            style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                        <span class="st">{{ item1.votes_count }}</span>
                                    </div>

                                    <!-- 
                                            <el-button class="margin-l" size="mini" v-if="item.user.id != $store.state.user.id"
                                                type="primary"  icon="el-icon-delete" circle>
                                            </el-button> -->

                                    <el-tooltip v-if="item.user_id == $store.state.user.id"
                                        class="st pointer icon-l margin-l" :content="$t('删除')" placement="top">
                                        <i @click="removeRecord(item1.id, index)" class="el-icon-delete"></i>
                                    </el-tooltip>
                                </div>

                                <div class="mt" v-else>
                                    <div class="flex flex-v-center flex-wrap"
                                        v-if="tag == 'MCM-L' || tag == 'MCM-R' || tag == 'HIW'">
                                        <div class="flex flex-v-center"
                                            v-for="(word, index) in item1.content.split(',')" :key="index">
                                            <span
                                                :class="{ 'wrong0': answers.length > 0 && answers.indexOf(word) == -1, 'right': answers.length > 0 && answers.indexOf(word) != -1 }">{{
                                                    word
                                                }}</span>

                                            <span v-if="index < item1.content.split(',').length - 1">,</span>
                                        </div>
                                    </div>

                                    <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'WFD'">
                                        <span class="word" :class="item.class" v-for="(item, index) in item1.contents"
                                            :key="index">
                                            {{ item.word }}
                                        </span>
                                    </div>

                                    <!-- <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'WFD'">
                                                <div class="flex flex-v-center" v-for="(word, index) in item1.content.split(' ')"
                                                    :key="index">
                                                    <span
                                                        :class="{ 'wrong0': answers.length > 0 && answers.indexOf(word.replace(/\W/g, '')) == -1 && answers.indexOf(word.replace(/\W/g, '').toLowerCase()) == -1, 'right': answers.length > 0 && (answers.indexOf(word.replace(/\W/g, '')) != -1 || answers.indexOf(word.replace(/\W/g, '').toLowerCase()) != -1) }">{{
                                                            word
                                                        }}</span>

                                                    <span v-if="index < item1.content.split(' ').length - 1">&nbsp;</span>
                                                </div>
                                            </div> -->

                                    <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'RO'">
                                        <div class="flex flex-v-center"
                                            v-for="(word, index) in item1.content.split(',')" :key="index">
                                            <span>{{
                                                word.split('/')[0]
                                                }}</span>

                                            <span v-if="index < item1.content.split(',').length - 1">,</span>
                                        </div>
                                    </div>


                                    <row-span :id="item.id" v-else-if="tag == 'SST' || tag == 'SWT'">
                                        <div class="flex flex-v-center ls1"
                                            v-for="(word, index) in item1.content.split('\n')" :key="index">
                                            <span v-html="word ? word : '&nbsp;'"></span>
                                        </div>

                                    </row-span>

                                    <row-span :id="item.id" v-else-if="tag == 'WE'">
                                        <div :class="{ 'word-mock': mock }" class="flex flex-v-center ls1"
                                            v-for="(word, index) in item1.content.split('\n')" :key="index">
                                            <div v-if="word"> {{ word }}</div>
                                            <div v-else style="height: 10px;"></div>
                                        </div>
                                    </row-span>


                                    <!-- <div class="flex flex-v-center flex-wrap" v-else-if="tag == 'SST' || tag == 'SWT'">
                                                <div class="flex flex-v-center ls1"
                                                    v-for="(word, index) in item1.content.split(',')" :key="index">
                                                    <span>{{ word }}</span>
                                                </div>


                                            </div> -->

                                    <div class="flex flex-v-center flex-wrap" v-else>
                                        <div class="flex flex-v-center"
                                            v-for="(word, index) in item1.content.split(',')" :key="index">
                                            <span
                                                :class="{ 'wrong0': answers.length > 0 && answers[index].split('/').indexOf(word) == -1, 'right': answers.length > 0 && answers[index].split('/').indexOf(word) != -1 }">{{
                                                    word
                                                }}</span>
                                            <span v-if="answers.length > 0"
                                                :class="{ 'wrong': answers.length > 0 && answers[index].split('/').indexOf(word) == -1 }">
                                                {{ answers[index].split('/').indexOf(word) == -1 ? '(' + answers[index]
                                                    +
                                                    ')' : '' }}
                                            </span>
                                            <span v-if="index < item1.content.split(',').length - 1">,</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-v-center margin-ts">

                                    <template v-if="['SWT', 'WE', 'SST'].indexOf(tag) != -1">
                                        <el-button size="mini" round type="primary" @click="showScore(item1)"
                                            v-if="((mock && item1.status == 1) || item1.result_time)">{{
                                                item1.score
                                            }}/{{ total }} {{ $t('查看评分') }}
                                        </el-button>

                                        <el-button :disabled="evaluate_item.id == item1.id"
                                            @click="evaluate(item1, index1, index)" icon="el-icon-files"
                                            v-if="item1.user_id == $store.state.user.id && item1.result_time == null && !mock"
                                            size="small" round type="primary">{{ evaluate_item.id ==
                                                item1.id ? `${$t('AI测评中')}...` : $t('AI测评') }}</el-button>
                                    </template>


                                    <template v-else>

                                        <el-button size="mini" round type="primary" @click="showScore(item1)"
                                            v-if="(item1.content.indexOf('.wav') == -1 && item1.content.indexOf('.mp3') == -1)">{{
                                                item1.score }}/{{ total }} {{ $t('查看评分') }}
                                        </el-button>

                                        <div @click="showScore(item1)"
                                            v-if="(item1.content.indexOf('.wav') == -1 && item1.content.indexOf('.mp3') == -1) && ['SWT'].indexOf(tag) == -1"
                                            class="st">
                                            {{ item1.score }}/{{ total
                                            }}分
                                        </div>
                                    </template>

                                    <div class="st2"
                                        :class="{ 'margin-x': (item.content.indexOf('.wav') == -1 && item.content.indexOf('.mp3') == -1) }">
                                        {{
                                            item1.record_time
                                        }}</div>

                                    <div v-if="(item1.content.indexOf('.wav') == -1 && item1.content.indexOf('.mp3') == -1)"
                                        class="flex flex-v-center">
                                        <el-tooltip class="st pointer icon-l margin-l" :content="$t('分享答案')"
                                            placement="top">
                                            <i @click="share(item1)" class="el-icon-share"></i>
                                        </el-tooltip>

                                        <div class="flex flex-v-center">
                                            <el-image @click="vote(item1)"
                                                :src="'/web/image/zan' + (item1.vote_id ? '_checked' : '') + '.png'"
                                                style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                            <span class="st">{{ item1.votes_count }}</span>
                                        </div>

                                        <el-tooltip v-if="item.user_id == $store.state.user.id"
                                            class="st pointer icon-l margin-l" :content="$t('删除')" placement="top">
                                            <i @click="removeRecord(item1.id, index)" class="el-icon-delete"></i>
                                        </el-tooltip>
                                    </div>

                                </div>
                                <el-divider></el-divider>
                            </div>
                        </div>
                    </div>

                    <div v-if="type == 'ccl'">
                        <div v-if="item.items && item.collapse">
                            <el-divider></el-divider>
                            <div v-for="(item1, index) in item.items" :key="'items_' + index">


                                <div v-for="(row, index) in item1.content" :key="index" style="margin:5px 0 20px">


                                    <div class="flex flex-v-center">
                                        <div class="st1" style="width:16px;font-weight: 700;">{{ parseInt(row.index) + 1
                                            }}.
                                        </div> <wave-player class="margin-x" borderRadius="10px"
                                            :src="row.url.indexOf('http') == 0 ? row.url : $host + row.url"></wave-player>
                                    </div>

                                    <div class="margin-ts" v-if="item1.audit_content[row.index]">

                                        <div class="st1 flex ">
                                            <el-tag size="small">{{ $t('老师点评') }}</el-tag>
                                            <div class="margin-l" style="color: #ccc;">{{ item1.audit_time }}</div>

                                        </div>
                                        <div class="st" style="margin-left: 30px;">
                                            <div>{{
                                                item1.audit_content[row.index].text }}</div>
                                            <wave-player v-if="item1.audit_content[row.index].url" borderRadius="10px"
                                                :src="item1.audit_content[row.index].url.indexOf('http') == 0 ? item1.audit_content[row.index].url : $host + item1.audit_content[row.index].url"></wave-player>
                                        </div>
                                        <!-- <el-divider></el-divider> -->
                                    </div>
                                </div>
                                <div class="flex flex-v-center margin-t">
                                    <div class="st2">{{
                                        item1.record_time
                                    }}</div>
                                    <el-tooltip class="st pointer icon-l margin-l" :content="$t('分享答案')"
                                        placement="top">
                                        <i @click="share(item1)" class="el-icon-share"></i>
                                    </el-tooltip>

                                    <div class="flex flex-v-center">
                                        <el-image @click="vote(item1)"
                                            :src="'/web/image/zan' + (item1.vote_id ? '_checked' : '') + '.png'"
                                            style="width:20px;height:20px;" class="margin-l pointer"></el-image>
                                        <span class="st">{{ item1.votes_count }}</span>
                                    </div>

                                    <!-- <el-button class="margin-l" size="mini" v-if="item.user.id == $store.state.user.id"
                                                type="primary" @click="removeRecord(item.id, index)" icon="el-icon-delete" circle>
                                            </el-button> -->
                                    <el-tooltip v-if="item.user.id == $store.state.user.id"
                                        class="st pointer icon-l margin-l" :content="$t('删除')" placement="top">
                                        <i @click="removeRecord(item1.id, index)" class="el-icon-delete"></i>
                                    </el-tooltip>
                                </div>
                                <el-divider></el-divider>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.items && item.items.length > 0" class="st1">已{{
                        item.collapse ? $t("展开") : $t("折叠")
                    }}{{
                            item.items.length
                        }}{{ $t('条记录') }}，<el-link type="primary" class="st" @click="item.collapse = !item.collapse">{{
                            item.collapse ? $t("收起") : $t("点击查看")
                        }}</el-link></div>
                </div>
            </div>
            <el-divider v-if="!mock"></el-divider>
        </div>
        <score-dialog :mock="mock" ref="scoreDialog"></score-dialog>
        <share-dialog ref="shareDialog"></share-dialog>
        <evaluate-dialog ref="evaluateDialog" @ended="evaluateEnd"></evaluate-dialog>
        <vip-alert ref="vipalert"></vip-alert>
    </div>
</template>

<script>

import WavePlayer from './WavePlayer.vue'
import scoreDialog from './ScoreDialog.vue'
import shareDialog from './ShareDialog.vue'
import rowSpan from './RowSpan.vue'
import evaluateDialog from './EvaluateDialog.vue'
import vipAlert from './VipAlert.vue'
export default {
    components: {
        vipAlert, WavePlayer, scoreDialog, shareDialog, rowSpan, evaluateDialog
    },
    name: "recordList",
    data() {
        return {
            loadingDialogVisible: false,
            loading: null,
            answers: [],
            total: 0,
            times: 5,
            evaluate_item: { id: 0 },
        };
    },

    watch: {
        answer() {
            this.format_answer();
        },
    },



    props: {
        list: {
            type: [],
            default: () => {
                return []
            },
        },

        answer: {
            type: String,
            default: "",
        },

        tag: {
            type: String,
            default: "",
        },

        source: {
            type: Object,
            default: () => {
                return {}
            },
        },

        type: {
            type: String,
            default: "pte",
        },

        mock: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.format_answer();
    },
    methods: {

        showScore(item) {
            if (['SWT', 'WE', 'SST'].indexOf(this.tag) != -1) {
                this.view(item);
            }
        },
        evaluateEnd() {
            console.log(this.evaluate_item)
            this.view(this.evaluate_item)
        },
        getRowHeight(id) {
            if (this.$refs['row' + id]) {
                return this.$refs['row' + id][0].offsetHeight > 84;
            }
            return false;
        },
        vote(item) {
            let data = {};
            if (item.vote_id) {
                data["id"] = item.vote_id;
            } else {
                data["record_id"] = item.id;
            }
            this.$http.post("/api/source/record/vote", data).then((res) => {
                item.vote_id = res.id;
                if (res.id) {
                    item.votes_count++;
                } else {
                    item.votes_count--;
                }

            })
        },

        share(item) {
            let href = this.$domain + "/record?id=" + item.id
            this.$refs.shareDialog.show(this.$t("分享练习"), href, "");
        },

        applyCheck(item) {
            this.$confirm('', this.$t('确认申请批改') + '?', {
                center: true,
                customClass: "reset",
                confirmButtonText: this.$t('确定'),
                cancelButtonText: this.$t('取消'),
                type: 'primary'
            }).then(() => {
                this.$http.post("/api/source/record/save", { id: item.id, status: 1 }).then(() => {
                    this.$message({
                        type: 'success',
                        message: this.$t('申请成功') + '!'
                    });

                    item.status = 1;
                })
            }).catch(() => {

            });
        },

        format_answer() {
            this.total = 0;
            if (this.answer) {
                if (this.tag == "WFD") {
                    let items = this.answer.split(" ");
                    console.log(items)
                    let answers = [];
                    items.forEach((item) => {
                        this.total++;
                        if (item.indexOf("^") == 0) {
                            answers = answers.concat(item.replace(/[^\w|\\/]/g, '').split('/'))
                        } else {
                            answers = answers.concat(item.replace(/[^\w|\\/]/g, '').toLowerCase().split('/'));
                        }
                    });
                    this.answers = answers;

                } else if (this.tag == "RO") {
                    this.total = this.answer.split(",").length - 1;
                } else if (this.tag == "WE") {
                    this.total = 15;
                } else if (this.tag == "SST") {
                    this.total = 10;
                } else if (this.tag == "SWT") {
                    this.total = 7;
                } else {
                    this.answers = this.answer.split(",");
                    this.total = this.answers.length;
                }

            } else {
                this.answers = [];
            }
        },

        format_number(val) {
            return parseFloat(val).toFixed(0);
        },

        view(item) {
            this.$refs.scoreDialog.show(item.id);
        },
        getTimes() {
            return new Promise((resolve) => {
                this.$refs.vipalert.show((flag) => {
                    resolve(flag)
                })
            })
        },

        async evaluate(item, index, index1) {
            console.log(item, index, index1)

            this.evaluate_item.id = item.id;
            if (this.$store.getters.getRole() == 0) {
                let flag = await this.getTimes();
                if (flag == 0) return;

                this.$refs.evaluateDialog.show(item.content);

                this.$http.post("/api/source/record/evaluate", { id: item.id }).then((res) => {
                    if (res) {

                        if (index == -1) {
                            this.list[index1]["result_time"] = res.result_time;
                            this.list[index1]["score"] = res.score;
                        } else {
                            this.list[index].items[index1]["result_time"] = res.result_time;
                            this.list[index].items[index1]["score"] = res.score;
                        }
                        this.$refs.evaluateDialog.hide(100);
                    }
                })
            } else {
                this.$refs.evaluateDialog.show(item.content);
                this.$http.post("/api/source/record/evaluate", { id: item.id }).then((res) => {
                    if (res) {
                        if (index == -1) {
                            this.list[index1]["result_time"] = res.result_time;
                            this.list[index1]["score"] = res.score;
                        } else {
                            this.list[index].items[index1]["result_time"] = res.result_time;
                            this.list[index].items[index1]["score"] = res.score;
                        }
                        this.$refs.evaluateDialog.hide(100);
                    }
                })
            }

        },

        removeRecord(id, index) {
            this.$confirm('', '确认删除此记录?', {
                center: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary'
            }).then(() => {
                this.$http.post("/api/source/record/remove", { id: id }).then((flag) => {
                    if (flag) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.list.splice(index, 1);
                    }
                })
            }).catch(() => {

            });
        }
    },
};
</script>

<style scoped>
.wrong {
    letter-spacing: 1px;
    color: red;
}

.right {
    letter-spacing: 1px;
    color: green;
}

.wrong0 {
    letter-spacing: 1px;
    color: red;
    text-decoration: line-through red;

}

/* .wrong1 {
    letter-spacing: 1px;
    text-decoration: line-through red;
} */

.wrong1 {
    color: red;
    font-weight: 500;
}

.wrong2 {
    font-weight: 500;
    text-decoration: line-through;
}

.avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.record-list>>>.el-divider--horizontal {
    margin: 10px 0 !important;
}
</style>
